import { KeyboardEvent, useEffect, useState } from 'react'

import { Tag } from '../tag'

type ITagInputProps = {
  name?: string
  placeholder?: string
  tags?: string[]
  onChange?: (tags: string[]) => void
  separator?: string
  enableBackspaceRemove?: boolean
  onRemoved?: (tag: string) => void
  disabled?: boolean
  isEditOnRemove?: boolean
  beforeAddValidate?: (tag: string, existingTags: string[]) => boolean
  width?: number
}

const defaultSeparators = ['Enter']

const TagInput = ({
  name,
  placeholder = 'Type and press enter',
  tags,
  onChange,
  separator,
  enableBackspaceRemove,
  disabled,
  isEditOnRemove,
  beforeAddValidate,
}: ITagInputProps) => {
  const [innerTags, setInnerTags] = useState<string[]>(tags || [])
  const [text, setText] = useState('')

  useEffect(() => {
    if (JSON.stringify(tags) !== JSON.stringify(innerTags)) onChange?.(innerTags)
  }, [innerTags])

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()

    if (!text && enableBackspaceRemove && innerTags.length && e.key === 'Backspace') {
      setText(isEditOnRemove ? `${innerTags[innerTags.length - 1]} ` : '')
      setInnerTags([...innerTags.slice(0, -1)])
    }

    if (text && ([separator, ...defaultSeparators].includes(e.key) || e.key === 'Meta')) {
      e.preventDefault()
      if (beforeAddValidate && !beforeAddValidate(text, innerTags)) return
      if (separator) {
        setInnerTags([
          ...innerTags,
          ...text
            .split(separator)
            .map((val) => val.replace(separator, ''))
            .filter((val) => val),
        ])
      } else {
        setInnerTags([...innerTags, text])
      }

      setText('')
    }
  }

  const onTagRemove = (index: number) => {
    setInnerTags((prev) => [...prev.filter((val, idx) => index !== idx)])
  }

  return (
    <div
      aria-labelledby={name}
      className='box-border flex min-h-[32px] w-full flex-wrap items-center gap-2 overflow-auto rounded border-[1px] border-solid border-gray-300 pl-2 pr-2 pb-1 pt-1'>
      {innerTags.map((tag, index) => (
        <Tag
          key={index}
          iconRight='CloseIcon'
          onIconRightClick={() => onTagRemove(index)}
          size='sm-no-h'>
          {tag}
        </Tag>
      ))}

      <input
        className='!border-none !outline-none focus:ring-0'
        type='text'
        value={text}
        name={name}
        onChange={(event) => setText(event.target.value)}
        placeholder={placeholder}
        onKeyUp={(event) => handleOnKeyDown(event)}
        disabled={disabled}
      />
    </div>
  )
}

export { TagInput }
